import { ILocalRepositoryCB } from "modules/auth/application/interfaces/repository/ILocalRepository";
import { ILocal } from "../../domain/models/ILocal";

const SaveLocalsUseCase = (locals: ILocal[], _localRepository: ILocalRepositoryCB) => {
    const localRepository = _localRepository();
    localRepository.setLocals(locals);

    const currentLocal = localRepository.getCurrentLocal();

    const findCurrentLocal = locals.find(item => item.id === currentLocal?.id)
    if (!currentLocal && !!locals.length) {
        localRepository.setCurrentLocal(locals[0]);
        return { currentLocal: locals[0], locals }
    }

    return { currentLocal, locals }
}

export default SaveLocalsUseCase