import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { BreadcrumbsEnum, IBreadcumbsRouter } from './IBreadcumbsRouter';

interface IBreadcumbsContext {
  routers?: IBreadcumbsRouter[],
  updateRouters: (values: IBreadcumbsRouter[]) => void
  homeUrl: string;
  placesUrl: string;
  updateLastRouter: (newValue: IBreadcumbsRouter) => void
  addRouter: (newValue: IBreadcumbsRouter) => void
  removeLast: () => void
  clearBreadCumbs: () => void
}

const BreadcumbsContext = createContext<IBreadcumbsContext>({} as IBreadcumbsContext);

export const BreadcumbsProvider: FC<{ homeUrl: string, placesUrl: string }> = ({ children, homeUrl, placesUrl }) => {
  const { currentLocal } = useLocal()
  const [routers, setRouters] = useState<IBreadcumbsRouter[]>([{ title: currentLocal?.name, label: "Local", id: BreadcrumbsEnum.LOCAL, url: homeUrl }])

  useEffect(() => {
    setRouters(prev => prev.map((itemRouter, index) => index === 0 ? { title: currentLocal?.name, label: "Local", id: BreadcrumbsEnum.LOCAL, url: homeUrl } : itemRouter));
  }, [currentLocal?.name, homeUrl])

  const updateRouters = useCallback((values: IBreadcumbsRouter[]) => {
    setRouters([{ title: currentLocal?.name, label: "Local", url: homeUrl }, ...values])
  }, [currentLocal?.name, homeUrl])

  const updateLastRouter = useCallback((newValue: IBreadcumbsRouter) => {
    setRouters(prev => prev.map((item, index) => index === prev.length - 1 ? { ...item, ...newValue } : item))
  }, [])

  const clearBreadCumbs = useCallback(() => {
    setRouters([])
  }, [])

  const addRouter = useCallback((newValue: IBreadcumbsRouter) => {

    setRouters(prev => {
      let newRouters = prev;
      const route = prev.find(item => newValue.id && item.id === newValue.id);

      if (route) {
        newRouters = prev.slice(0, prev.indexOf(route) + 1)
      } else {
        newRouters = [...prev, newValue]
      }

      return newRouters
    })
  }, [])

  const removeLast = useCallback(() => {
    setRouters(prev => prev.filter((item, index) => index !== prev.length - 1))
  }, [])



  return (
    <BreadcumbsContext.Provider value={{
      routers, updateRouters, homeUrl, updateLastRouter, addRouter, removeLast, clearBreadCumbs, placesUrl
    }}>
      <>{children}</>
    </BreadcumbsContext.Provider>
  );
};


export const useBreadcumbs = () => {
  const context = useContext(BreadcumbsContext);
  return context;
};