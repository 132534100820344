import React, { createContext, FC, useContext } from "react";
import { IAuthContext } from "./IAuthAdminContext";
import { UseAuthContext } from "../hooks/UseAuth";

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC<{ disableRedirectToLogin?: boolean }> = ({ children, disableRedirectToLogin }) => {
  const {
    saveAuth,
    isAuth,
    logout,
    auth,
    hasAccessRole,
  } = UseAuthContext(disableRedirectToLogin);
  return (
    <AuthContext.Provider value={{ saveAuth, auth, isAuth, logout, hasAccessRole }}>
      <>{children}</>
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  return context;
};




