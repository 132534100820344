import { ILocalConfigurationResponse } from 'modules/local/domain/dtos/IGetLocalResumeResponse';
import { IlocalService } from 'modules/local/domain/interfaces/IlocalService';

const GetLocaLConfigurationUseCase = async (
  localConfigurationService: IlocalService,
  localId: string,
): Promise<ILocalConfigurationResponse> => {
  const result = await localConfigurationService.getLocalConfiguration(localId);
  return result;
};

export default GetLocaLConfigurationUseCase;