import { Backdrop, CircularProgress, LinearProgress } from "@material-ui/core";
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from "react";
import { ToastContainer, toast as toastify, TypeOptions } from "react-toastify";
import styles from "./UserInterfaceContext.module.scss";
import { theme, themeMUI } from "styles/temas/MateriaUi";
import { ThemeProvider } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@mui/material"
import "react-toastify/dist/ReactToastify.css";
import TutorialCardProvider from "modules/onBoardingTutorials/presentation/components/TutorialCardProvider";
interface IUserInterfaceProps {
  toast: (message: string, type: TypeOptions) => void;
  showLoading: () => void;
  hideLoading: () => void;
  isMobile: boolean;
  dimensions: { width: number, heigth: number }
}

const mobileSize = 700;

const UserInterfaceContext = createContext<IUserInterfaceProps>({} as IUserInterfaceProps);

export const UiProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [topLoading, setTopLoading] = useState(false);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, heigth: window.innerHeight });
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileSize
  )
  const [countLoad, setCountLoad] = useState(0)

  const toast = useCallback((message: string, type: TypeOptions) => {
    toastify(message, {
      type: type,
      position: "bottom-center"
    });
  }, []);

  // const showLoading = useCallback(() => {
  //   setLoading(true);
  // }, []);
  // const hideLoading = useCallback(() => {
  //   setLoading(false);
  // }, []);



  const showLoading = useCallback(() => {
    setLoading(true)
    setCountLoad(prev => prev + 1)
  }, [])

  const hideLoading = useCallback(() => {
    setCountLoad(prev => !!prev ? prev - 1 : 0)
    setLoading(false)
  }, [])


  const getDimensions = useCallback(() => {
    const { innerWidth, innerHeight } = window;

    setDimensions({ width: window.innerWidth, heigth: window.innerHeight });

    if (innerWidth <= mobileSize) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, []);


  useEffect(() => {
    window.addEventListener("resize", getDimensions)
    return () => window.removeEventListener("resize", getDimensions)
  }, [getDimensions])

  return (
    <UserInterfaceContext.Provider value={{ toast, showLoading, hideLoading, isMobile, dimensions }}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={themeMUI}>
          {topLoading && <LinearProgress color={"secondary"} style={{ position: "fixed", zIndex: 10, top: 0, left: 0, right: 0 }} />}
          <Backdrop open={!!countLoad} className={styles.backdrop}>
            <CircularProgress className={styles.circularProgress} />
          </Backdrop>
          {children}
          <TutorialCardProvider />
          <ToastContainer style={{ zIndex: 2147483646 }} />
        </MuiThemeProvider>
      </ThemeProvider>
    </UserInterfaceContext.Provider>
  );
};

export const useUi = () => {
  const userInterfaceContext = useContext(UserInterfaceContext);
  return userInterfaceContext;
};
