import { create } from "zustand";
import { ILocal } from "modules/local/domain/models/ILocal";
import UpdateCurrentLocalUseCase from "modules/local/application/useCases/UpdateCurrentLocalUseCase";
import { LocalRepository } from "services/repository/local/LocalRepository";
import SaveLocalsUseCase from "modules/local/application/useCases/SaveLocalsUseCase";
import GetLocalConfigurationUseCase from "modules/local/application/useCases/GetLocalConfigurationUseCase";
import LocalService from "services/api/local/LocalService";
import { ILocalConfigurationResponse } from "modules/local/domain/dtos/IGetLocalResumeResponse";

export interface ILocalStore {
    updateChangeCurrentLocal: (newCurrentLocals: ILocal) => ILocal,
    getLocalConfiguration: (localId: string) => Promise<ILocalConfigurationResponse>,
    setLocals: (locals: ILocal[]) => void,
    currentLocal?: ILocal,
    locals?: ILocal[],
    localConfiguration?: ILocalConfigurationResponse,
}

export const UseLocalStore = create<ILocalStore>((set,get) => ({
  updateChangeCurrentLocal: ( newCurrentLocals: ILocal) => {
        UpdateCurrentLocalUseCase(
          newCurrentLocals,
          LocalRepository
        );
        get().getLocalConfiguration(newCurrentLocals.id ?? undefined);
        set({ currentLocal: newCurrentLocals });
        return newCurrentLocals;
  },
  getLocalConfiguration: async (localId: string) => {
      const localService = LocalService();
      const response = await GetLocalConfigurationUseCase(localService, localId);
      set({ localConfiguration: response });
      return response;
  },
  setLocals: (locals: ILocal[]) => {
    const savedLocals = SaveLocalsUseCase(locals, LocalRepository);
    if (savedLocals.currentLocal?.id) {
      get().getLocalConfiguration(savedLocals.currentLocal.id);
    }
    set({ locals: locals, currentLocal: savedLocals.currentLocal?? undefined});
  },
  currentLocal: LocalRepository().getCurrentLocal()?? undefined,
  locals: LocalRepository().getLocals()?? undefined,
  localConfiguration: undefined,
}));
