import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import React, { useEffect, useState } from 'react'
import { IUser } from '../../model/IUser'

const UseUserContext = () => {
    const [user, setUser] = useState<IUser>();
    const { auth } = useAuth();

    useEffect(() => {
        setUser(auth?.user);
        return () => {

        }
    }, [auth])

    return (
        {user}
    )
}

export default UseUserContext
