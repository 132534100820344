import { ILocalConfigurationResponse } from "modules/local/domain/dtos/IGetLocalResumeResponse"
import { IGetLocalsListRequest, IGetLocalsListResponse } from "modules/local/domain/dtos/IGetLocalsListResponse"
import { IlocalService } from "modules/local/domain/interfaces/IlocalService"
import { api } from "../Api"

const LocalService = (): IlocalService => {
    const getLocalsList = async (request: IGetLocalsListRequest): Promise<IGetLocalsListResponse> => {
        const response = await api.get<IGetLocalsListResponse>("/dashboard/v2/sales/PlacesSelector", {
          params: {
            ...request,
            page: (request.page ?? 1) - 1,
            keyword: request.search
          }
        });
        return response.data
    }

    const getLocalConfiguration = async (localId: string): Promise<ILocalConfigurationResponse> => {
        const response = await api.get(`/Proprietario/BuscarConfiguracoesDoLocal/${localId}`);
        const config = response.data.configuracaoSistemasGestao.filter((x: { tipoSistema: { descricao: string, id: number } }) => x.tipoSistema.id === 2);
        const result: ILocalConfigurationResponse = {
          ...response.data,
          cashlessPrepaid: (config.length > 0)
        };

        return result;

    };
    return ({ getLocalsList, getLocalConfiguration });
}

export default LocalService;
