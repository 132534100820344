import { Theme as ThemeMui, createTheme as createThemeMui } from "@mui/material";
import { Theme, createTheme } from "@material-ui/core";

const primary = "#32008e";
const onPrimary = "#fff";
const secondary = "#955CFF";
const secondaryDark = "#32008e";
const onSecondary = "#fff";
const tertiary = "#ff00a6";
const onTertiary = "#fff";
const background = "#fff";
const text = "#5f5f5f";
const disabled = "#ddd";
const sucess = "#219653";
const warning = "#ffb300";
const erro = "#32008e";



export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    allVariants: {
      color: text,
      textTransform: "none",
      fontFamily: `
       Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;`,
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
      color: "primary",
      size: "small",
    },
    MuiButton: {
      color: "primary",
      variant: "contained",
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        color: text,
      },
    },
  },
});

export const themeMUI: ThemeMui = createThemeMui({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    allVariants: {
      color: text,

      textTransform: "none",
      fontFamily: `
       Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;`,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullWidth: true,
        color: "primary",
        size: "small",
      },
  },
  MuiButton: {
    defaultProps: {
      color: "primary",
      variant: "contained",
    },
  },
}
});
