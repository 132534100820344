import { ISelectedLocalsRepository } from "modules/dashboard/application/interfaces/ISelectedLocalsRepository";

export const DashboardSelectedLocals = (): ISelectedLocalsRepository => {
  const key = "@dashboardSelectedLocals";
  const currentLocalkey = "@localCurrent";

  const set = (selectedLocals: string[]) => {
    localStorage.setItem(key, JSON.stringify(selectedLocals));
  };

  const get = (): string[] | null => {
    try {
      const dashboardSelectedLocalsString = localStorage.getItem(key)
      const currentLocalString = localStorage.getItem(currentLocalkey);
      if (dashboardSelectedLocalsString) {
        return JSON.parse(dashboardSelectedLocalsString) as string[];
      } else if (currentLocalString) {
        return [JSON.parse(currentLocalString).id] as string[];
      }

      return null;
    } catch (error) {
      return null;
    }
  };

  const remove = () => {
    localStorage.removeItem(key);
  };

  return {
    set,
    get,
    remove,
  };
};


