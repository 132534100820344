import React, { createContext, FC, useContext } from 'react';
import { IUser } from '../../model/IUser';
import UseUserContext from '../hooks/UseUserContext';

interface IUserContext {
    user?: IUser
}

const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider: FC = ({ children }) => {
    const { user } = UseUserContext()
    return (
        <UserContext.Provider value={{ user }}>
            <>{children}</>
        </UserContext.Provider>
    );
};


export const useUser = () => {
    const context = useContext(UserContext);
    return context;
};