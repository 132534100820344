import { api } from "../Api";
import { IPlansService } from "modules/plans/domain/interfaces/IPlansService";
import { IGetPlansResponse } from "modules/plans/domain/dtos/IGetPlansResponse";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IGetSubscriptionResponse } from "modules/plans/domain/dtos/IGetSubscriptionResponse";
import { IGetCustomerInvoicesResponse } from "modules/plans/domain/dtos/IGetCustomerInvoicesResponse";
import { ISavePowerBIConfigRequest } from "modules/bi/domain/ISavePowerBIConfigRequest";
import { IGetPowerBIConfigResponse } from "modules/bi/domain/IGetPowerBIConfigResponse";
import { IGetFeaturesResponse } from "modules/plans/domain/dtos/IGetFeaturesResponse";
import { IGetLocalPlansResponse } from "modules/plans/domain/dtos/IGetLocalPlansResponse";
import { IGetDowngradeResponse } from "modules/plans/domain/dtos/IGetDowngradeResponse";
import { IGetHistoryResponse } from "modules/plans/domain/dtos/IGetHistoryResponse";
import { plansUrl } from "Enviroment";
import { IGetPaymentHistoryResponse } from "modules/plans/domain/dtos/IGetPaymentHistoryResponse";

const PlansApi = (): IPlansService => {
  const baseUrl = plansUrl;

  const getPlans = async (): Promise<IGetPlansResponse[]> => {
    const response = await api.get(`${baseUrl}/plans`, { params: { disableError: true } });
    return response.data;
  };

  const getPlan = async (planId: string): Promise<IGetPlansResponse> => {
    const response = await api.get(`${baseUrl}/plans/${planId}`, { params: { disableError: true } });
    return response.data;
  };

  const getAdditionalFeatures = async (featureType: FeatureTypeEnum[], page?: number, pageSize?: number): Promise<IGetFeaturesResponse[]> => {
    const response = await api.get(`${baseUrl}/features/additional`, { params: { featureTypeValue: featureType.join(','), page, pageSize, disableError: true } });
    return response.data;
  };

  const subscribePlan = async (userDocument: string, ownerId: string, planId: string, additionalFeatures?: string[], downgradeSolicitationId?: string): Promise<void> => {
    const addFeatures = additionalFeatures?.filter(x => !!x);
    return await api.post(`${baseUrl}/subscriptions`, { userDocument, ownerId, planId, additionalFeatures: addFeatures, downgradeSolicitationId, disableError: true });
  }

  const subscribeFeature = async (userDocument: string, ownerId: string, featureId: string): Promise<void> => {
    return await api.post(`${baseUrl}/subscriptions/additional-features`, { userDocument, ownerId, featureId, disableError: true });
  }

  const getSubscription = async (ownerId: string): Promise<IGetSubscriptionResponse> => {
    const response = await api.get(`${baseUrl}/subscriptions/owner/${ownerId}`, { params: { disableError: true } });
    return response.data;
  };

  const getCustomerInvoices = async (ownerId: string): Promise<IGetCustomerInvoicesResponse[]> => {
    const response = await api.post('Admin/BuscarFaturasDoCliente', { localClienteId: ownerId });

    return response.data.map((x: any) => ({
      dueDate: x.dataVencimento,
      description: x.descricao,
      value: x.valor,
      status: x.status,
      billetUrl: x.linkBoleto,
      debitInTransactions: x.debitoEmTransacoes,
      billingType: x.tipoCobranca,
      paid: x.quitada,
      invoiceUrl: x.notaFiscal,
    }));
  }

  const removePowerBIConfig = async (ownerId: string): Promise<void> => {
    return await api.delete(`${baseUrl}/features/additional/powerbi/owner/${ownerId}`);
  }

  const savePowerBIConfig = async (ownerId: string, request: ISavePowerBIConfigRequest[]): Promise<void> => {
    return await api.put(`${baseUrl}/features/additional/powerbi/owner/${ownerId}`, request);
  }

  const getPowerBIConfig = async (ownerId?: string, page?: number, powerBiNotConfigured?: boolean): Promise<IGetPowerBIConfigResponse> => {
    const response = await api.get(`${baseUrl}/features/additional/powerbi`, { params: { ownerId, page, powerBiNotConfigured } });
    return response.data;
  }

  const getPowerBiHistory = async (ownerId?: string): Promise<IGetHistoryResponse[]> => {
    const response = await api.get(`${baseUrl}/subscriptions/owner/${ownerId}/change-histories`);
    return response.data;
  }

  const requestDowngrade = async (ownerId: string, userDocument: string, downgradeToPlanId: string): Promise<void> => {
    return await api.post(`${baseUrl}/subscriptions/downgrade`, { ownerId, userDocument, downgradeToPlanId });
  }

  const getDowngradeSolicitations = async (ownerId?: string): Promise<IGetDowngradeResponse[]> => {
    const response = await api.get(`${baseUrl}/subscriptions/downgrade`, { params: { ownerId } });
    return response.data;
  }

  const getLocalPlans = async (page: number, planId?: string, keyword?: string, hasDowngradeSolicitation?: boolean): Promise<IGetLocalPlansResponse> => {
    const response = await api.get(`${baseUrl}/locals`, { params: { planId, keyword, hasDowngradeSolicitation, page } });
    return response.data;

    // return {
    //   currentPage: 1,
    //   totalPages: 2,
    //   totalRegisters: 25,
    //   data: [{
    //     ownerId: '49953f8a-8a69-4a82-bf3e-8f18396313bb',
    //     ownerName: 'Clube Chalezinho',
    //     actualPlanId: '39c7e29e-bd87-4788-9535-ed7c028ab4a4',
    //     actualPlanName: 'Plano Básico',
    //     lastPurchaseDate: new Date().toISOString(),
    //   },
    //   {
    //     ownerId: '49953f8a-8a69-4a82-bf3e-8f18396313bb',
    //     ownerName: 'Clube Chalezinho 2',
    //     actualPlanId: 'aca7e552-3b68-4364-891b-5a3eb1335b83',
    //     actualPlanName: 'Plano Premium',
    //     lastPurchaseDate: new Date().toISOString(),
    //   },
    //   {
    //     ownerId: '49953f8a-8a69-4a82-bf3e-8f18396313bb',
    //     ownerName: 'Clube Chalezinho 3',
    //     actualPlanId: 'aca7e552-3b68-4364-891b-5a3eb1335b83',
    //     actualPlanName: 'Plano Premium',
    //     lastPurchaseDate: new Date().toISOString(),
    //   },
    //   ]
    // }
  }

  const getHistory = async (ownerId?: string): Promise<IGetHistoryResponse[]> => {
    const response = await api.get(`${baseUrl}/subscriptions/owner/${ownerId}/change-histories`);
    return response.data;
    
    // return [
      //   {
        //     changeDate: new Date().toISOString(),
        //     changedBy: '',
        //     changeDescription: 'Assinou o Plano Básico'
        //   },
        //   {
          //     changeDate: new Date().toISOString(),
    //     changedBy: 'Ramon Campos',
    //     changeDescription: 'Assinou o Plano Premium'
    //   },
    //   {
    //     changeDate: new Date().toISOString(),
    //     changedBy: 'Gabriel Esteves',
    //     changeDescription: 'Solicitou ir para o Plano Básico'
    //   },
    //   {
      //     changeDate: new Date().toISOString(),
      //     changedBy: 'Samuel Antunes via Admin',
      //     changeDescription: 'Alterou para o Plano Básico'
      //   },
      // ]
    }

    const getPaymentHistory= async (ownerId: string, column: string, orientation: "ASC" | "DESC", page: number, pageSize: number): Promise<IGetPaymentHistoryResponse> => {
      const response = await api.get(`${baseUrl}/payments`, { params: {
        ownerId,
        Page: page,
        PageSize: pageSize,
        [`Sort.Property`]: column,
        [`Sort.Orientation`]: orientation,
      }});
      return response.data;
    }

  return {
    getPlans,
    getPlan,
    getAdditionalFeatures,
    subscribePlan,
    subscribeFeature,
    getSubscription,
    getCustomerInvoices,
    removePowerBIConfig,
    savePowerBIConfig,
    getPowerBIConfig,
    getPowerBiHistory,
    requestDowngrade,
    getDowngradeSolicitations,
    getLocalPlans,
    getHistory,
    getPaymentHistory,
  };
};

export default PlansApi;
