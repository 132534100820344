export interface IBreadcumbsRouter {
  id?: string,
  title?: string | undefined;
  label?: string | undefined;
  url?: string | undefined;
}

export enum BreadcrumbsEnum {
  LOCAL = '@local',
  ADD_CONVIDADOS = '@addConvidados',
  FECHAMENTO = '@fechamento',
  LISTA_CONVIDADOS = '@listasDeConvidados',
  CONVIDADOS = '@convidados',
  GERENCIA = '@gerencia',
  EVENTOS = '@eventos',
  ADD_EVENTO = '@addEvento'
}