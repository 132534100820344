
export const apiUrl = process.env.REACT_APP_API_URL
export const deviceConfigApiUrl = process.env.REACT_APP_DEVICE_CONFIG_API_URL
export const terminaUrl = process.env.REACT_APP_TERMINAL_URL
export const crmUrl = process.env.REACT_APP_CRM_URL
export const fidelityUrl = process.env.REACT_APP_FIDELITY_URL
export const plansUrl = process.env.REACT_APP_PLANS_URL
export const socialUrl = process.env.REACT_APP_SOCIAL_URL
export const invoiceUrl = process.env.REACT_APP_INVOICE_URL
export const decryptKey = process.env.REACT_APP_DECRYPT_KEY
export const decryptIv = process.env.REACT_APP_DECRYPT_IV
export const erpUrl = process.env.REACT_APP_ERP_URL
export const meepHub = process.env.REACT_APP_URL_MEEP_HUB
export const cryptPassKey = process.env.REACT_APP_CRYPT_PASS_KEY
export const cryptPassIv = process.env.REACT_APP_CRYPT_PASS_IV
