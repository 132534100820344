import React, { ReactNode, forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import styles from './TutorialCard.module.scss'
import { Backdrop, Popover, PopoverActions } from '@mui/material';
import icon from './TutorialIcon.svg'
import ConfettiExplosion from 'react-confetti-explosion';
import Button from 'components/ui/Button/Button';
import Utils from 'services/utils/Utils';


export interface TutorialCardOptions {
    action?: () => void | Promise<void>,
    onClose?: () => void,
    title?: string | ReactNode,
    description?: string | ReactNode,
    buttonLabel?: string | ReactNode,
    enabledBackdrop?: boolean,
    disabledClose?: boolean,
    enabledConffeti?: boolean,
    anchorOrigin?: {
        vertical: 'top' | 'center' | 'bottom' | number;
        horizontal: 'left' | 'center' | 'right' | number;
    },
    transformOrigin?: {
        vertical: 'top' | 'center' | 'bottom' | number;
        horizontal: 'left' | 'center' | 'right' | number;
    },
    content?: React.ReactNode
    arrowPosition?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' | 'left-top' | 'left-center' | 'left-bottom' | 'right-top' | 'right-center' | 'right-bottom'
    buttonColor?: "inherit" | "default" | "primary" | "secondary"
    scroll?: ScrollLogicalPosition
    marginThreshold?: number
}
export interface TutorialCardRef {
    open: (element: HTMLButtonElement | HTMLDivElement | null, options: TutorialCardOptions) => void
    close: () => void
}

export interface ITutorialCardProps {
    //propertys
    // tutorialCard: {
    //     element: HTMLButtonElement | HTMLDivElement | null;
    //     options: TutorialCardOptions;
    // } | null
}

const TutorialCard = forwardRef<TutorialCardRef, ITutorialCardProps>((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | HTMLDivElement | null>(null);
    const [options, setOptions] = React.useState<TutorialCardOptions | null>(null);
    const [openConffeti, setOpenConffeti] = React.useState(false);

    const [lastElement, setLastElement] = React.useState<{ ref: HTMLButtonElement | HTMLDivElement | null, zIndex: string }>();

    const actionRef = React.useRef<PopoverActions | null>(null);

    const openCard = useCallback(async (element: HTMLButtonElement | HTMLDivElement | null, options: TutorialCardOptions) => {
        
        if (lastElement?.ref) {
            lastElement?.ref.style.setProperty('z-index', lastElement.zIndex);
        }
        
        setAnchorEl(element);
        setLastElement({ ref: element, zIndex: element?.style.getPropertyValue('z-index') ?? '' });
        element?.style.setProperty('z-index', '10001');
        element?.scrollIntoView({ behavior: 'smooth', block: options.scroll ?? 'center' });
        setOptions(options);

        await Utils.wait(500);

        if (actionRef.current) {
            actionRef.current.updatePosition();
        }
    }, [lastElement]);

    const closeCard = useCallback(() => {
        anchorEl?.style.setProperty('z-index', lastElement?.zIndex ?? '');
        setAnchorEl(null);
        options?.onClose?.();
    }, [anchorEl?.style, lastElement?.zIndex, options]);

    const actionHandler = useCallback(() => {
        if(options?.enabledConffeti)
            setOpenConffeti(true);
        options?.action?.();
        
    }, [options]);



    useImperativeHandle(
        ref,
        () => {
            return {
                open: openCard,
                close: closeCard
            };
        },
        [openCard, closeCard],
    )


    const open = Boolean(anchorEl);

    const arrow = useMemo(() => {
        if (options?.arrowPosition === 'top-left')
            return <div className={styles.arrowTopLeft} />
        if (options?.arrowPosition === 'top-center')
            return <div className={styles.arrowTopCenter} />
        if (options?.arrowPosition === 'top-right')
            return <div className={styles.arrowTopRight} />
        if (options?.arrowPosition === 'bottom-left')
            return <div className={styles.arrowBottomLeft} />
        if (options?.arrowPosition === 'bottom-center')
            return <div className={styles.arrowBottomCenter} />
        if (options?.arrowPosition === 'bottom-right')
            return <div className={styles.arrowBottomRight} />
        if (options?.arrowPosition === 'left-top')
            return <div className={styles.arrowLeftTop} />
        if (options?.arrowPosition === 'left-center')
            return <div className={styles.arrowLeftCenter} />
        if (options?.arrowPosition === 'left-bottom')
            return <div className={styles.arrowLeftBottom} />
        if (options?.arrowPosition === 'right-top')
            return <div className={styles.arrowRightTop} />
        if (options?.arrowPosition === 'right-center')
            return <div className={styles.arrowRightCenter} />
        if (options?.arrowPosition === 'right-bottom')
            return <div className={styles.arrowRightBottom} />
        return null;
    }, [options?.arrowPosition]);

    return (<>
        <Popover
            id={styles.TutorialCard}
            open={open}
            action={actionRef}
            anchorEl={anchorEl}
            marginThreshold={options?.marginThreshold ?? 8}
            PaperProps={{
                className: styles.paper
            }}
            style={{ zIndex: 10001 }}
            anchorPosition={{ top: 8, left: 0 }}
            onClose={!options?.disabledClose ? closeCard : undefined}
            anchorOrigin={options?.anchorOrigin ?? {
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={options?.transformOrigin ?? {
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {arrow}
            {
                options?.content ??
                <>
                    <div className={styles.header}>
                        <span>{options?.title}</span><img src={icon} alt="icon" />
                    </div>
                    <div className={styles.container}>
                        {options?.description}
                    </div>
                    <div className={styles.action}>
                        {options?.action && <Button color={options.buttonColor} fullWidth onClick={actionHandler}>{options?.buttonLabel ?? 'Entendi'}</Button>}
                    </div>
                </>
            }
        </Popover>

        <Backdrop open={open && !!options?.enabledBackdrop} onClick={closeCard} style={{ zIndex: 10000 }} />


        {
            <div className={styles.conffetiContainer}>
                {openConffeti && <ConfettiExplosion width={1600} onComplete={() => setOpenConffeti(false)} />}
            </div>
        }
    </>
    );
})
export default TutorialCard