import { create } from "zustand";
import { TutorialCardOptions, TutorialCardRef } from "../presentation/components/TutorialCard";

interface TutorialCardStore {
    tutorialCard: { element: HTMLButtonElement | HTMLDivElement | null, options: TutorialCardOptions } | null;
    open: (tutorialCard: { element: HTMLButtonElement | HTMLDivElement | null, options: TutorialCardOptions }) => void;
    close: () => void;
}

const UseTutorialCardStore = create<TutorialCardStore>((set) => ({
    tutorialCard: null,
    open: (tutorialCard) => set({ tutorialCard }),
    close: () => set({ tutorialCard: null }),
}));


export default UseTutorialCardStore;


export const tutorialCard = UseTutorialCardStore.getState().tutorialCard;
export const openTutorialCard = UseTutorialCardStore.getState().open;
export const closeTutorialCard = UseTutorialCardStore.getState().close;


UseTutorialCardStore.subscribe((state)=> console.log(state.tutorialCard))