import { IRemovedChartsRepository } from "modules/dashboard/application/interfaces/IRemovedChartsRepository";

export const DashboardRemovedCharts = (): IRemovedChartsRepository => {
  const key = "@dashboardRemovedCharts";
  const auth = "@auth";

  const getLoggedUser = () => {
    const loggedUserData = localStorage.getItem(auth);
    if (loggedUserData) {
      return JSON.parse(loggedUserData)?.user?.id;
    }

    return null;
  }

  const set = (removedCharts: string[]) => {
    const loggedUserId = getLoggedUser();

    if (loggedUserId) localStorage.setItem(`${key}_${loggedUserId}`, JSON.stringify(removedCharts));
  };

  const get = (): string[] | null => {
    try {
      const loggedUserId = getLoggedUser();
      const dashboardRemovedChartsString = localStorage.getItem(`${key}_${loggedUserId}`)

      if (dashboardRemovedChartsString) {
        return JSON.parse(dashboardRemovedChartsString);
      } 

      return null;
    } catch (error) {
      return null;
    }
  };

  const remove = () => {
    const loggedUserId = getLoggedUser();

    localStorage.removeItem(`${key}_${loggedUserId}`);
  };

  return {
    set,
    get,
    remove,
  };
};


