import React, { FC, useEffect } from 'react'
import styles from './TutorialCardProvider.module.scss'
import UseTutorialCardStore from '../../store/TutorialCardStore';
import TutorialCard, { TutorialCardRef } from './TutorialCard';
export interface ITutorialCardProviderProps {
    //propertys
}
const TutorialCardProvider: FC<ITutorialCardProviderProps> = () => {
    const [tutorialCard] = UseTutorialCardStore(state => [state.tutorialCard]);
    const tutorialCardRef = React.useRef<TutorialCardRef>(null);
    useEffect(() => {
        if (tutorialCard?.element && tutorialCard?.options) {
            tutorialCardRef.current?.open(tutorialCard?.element, tutorialCard?.options);
        } else {
            tutorialCardRef.current?.close();
        }
    }, [tutorialCard])
    return (
        <TutorialCard ref={tutorialCardRef} />
    )
}
export default TutorialCardProvider