import { ILocalRepositoryCB } from "modules/auth/application/interfaces/repository/ILocalRepository"
import { ILocal } from "../../domain/models/ILocal"

const UpdateCurrentLocalUseCase = (newCurrentLocal: ILocal, _localRepository: ILocalRepositoryCB) => {
    const localRepository = _localRepository();
    localRepository.setCurrentLocal(newCurrentLocal);
    return newCurrentLocal;
}

export default UpdateCurrentLocalUseCase
