import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { GetSubscriptionUseCase } from 'modules/plans/application/GetSubscriptionUseCase';
import React, { createContext, FC, useCallback, useContext, useState } from 'react';
import PlansApi from 'services/api/plans/PlansApi';
import { ISubscription } from '../interfaces/ISubscription';
import { GetPlansUseCase } from 'modules/plans/application/GetPlansUseCase';
import { IPlans } from '../interfaces/IPlans';
import { FeatureTypeEnum } from '../interfaces/FeatureTypeEnum';
import { GetPlanUseCase } from 'modules/plans/application/GetPlanUseCase';
import { GetAdditionalFeaturesUseCase } from 'modules/plans/application/GetAdditionalFeaturesUseCase';
import { IFeatures } from '../interfaces/IFeatures';

interface IPlansContext {
    isLoading: boolean;
    plans?: IPlans[];
    subscription?: ISubscription;
    getPlans: () => Promise<IPlans[] | undefined>;
    getPlan: (planId: string) => Promise<IPlans>;
    getSubscription: (localId?: string) => Promise<ISubscription | undefined>;
    checkPermission: (featureType: FeatureTypeEnum) => boolean;
    getAdditionalFeatures: (featureTypes: FeatureTypeEnum[]) => Promise<IFeatures[]>;
}

const PlansContext = createContext<IPlansContext>({} as IPlansContext);
const service = PlansApi();

export const PlansProvider: FC = ({ children }) => {
    const { currentLocal } = useLocal();
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState<ISubscription>();
    const [plans, setPlans] = useState<IPlans[]>();

    const getPlans = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await GetPlansUseCase(service);
            setPlans(response);
            return response;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getPlan = useCallback(async (planId: string) => {
        try {
            setIsLoading(true);
            const response = await GetPlanUseCase(service, planId);
            return response;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getSubscription = useCallback(async (localId?: string) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                const response = await GetSubscriptionUseCase(service, localId || currentLocal.id);
                setSubscription(response);
                return response;
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    const getAdditionalFeatures = useCallback(async (featureTypes: FeatureTypeEnum[]) => {
        try {
            const response = await GetAdditionalFeaturesUseCase(service, featureTypes);
            return response;
        } finally {

        }
    }, [])

    const checkPermission = useCallback((featureType: FeatureTypeEnum) => {
        if (!!subscription?.features) {
            return !!subscription?.features?.find(x => x.featureTypeValue === featureType);
        }
        return false;
    }, [subscription?.features]);

    return (
        <PlansContext.Provider value={{ isLoading, plans, subscription, getPlans, getSubscription, checkPermission, getAdditionalFeatures, getPlan }}>
            <>{children}</>
        </PlansContext.Provider>
    );
};

export const usePlans = () => {
    const context = useContext(PlansContext);
    return context;
};