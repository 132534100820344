import React from "react";
import Routes from "routers/Routes";
import { BrowserRouter, Route } from "react-router-dom";
import { UiProvider } from "contexts/userInterface/UserInterfaceContext";
import { ApiProvider } from "contexts/api/ApiContext";
import "styles/globals.scss";
import "styles/vars.scss";
import { QueryClientProvider } from "react-query";
import { queryClient } from "services/api/Api";

if (!process.env.REACT_APP_DEV) {
  window.console.log = (text: string) => { };
}
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UiProvider>
        <BrowserRouter>
          <ApiProvider>
            <Routes></Routes>
          </ApiProvider>
        </BrowserRouter>
      </UiProvider>
    </QueryClientProvider>
  );
}

export default App;
