import axios from "axios";
import { deviceConfigApiUrl } from "Enviroment";

const baseURL = deviceConfigApiUrl;

const userId = localStorage.getItem('@auth');
const userData = userId && JSON.parse(userId);

export const deviceConfigApi = axios.create({
  baseURL,
  headers: {
    'User': userData?.user?.id,
  },
});
