import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';


export interface ITips {
    id: string,
    value: string,
}

interface ITipsContext {
    onClickOpenTips: () => void
    onClickCloseTips: () => void
    tips?: ITips
    openTips: boolean
    setTips: React.Dispatch<React.SetStateAction<ITips | undefined>>
}

const TipsContext = createContext<ITipsContext>({} as ITipsContext);

export const TipsProvider: FC = ({ children }) => {
    const [tips, setTips] = useState<ITips>();
    const [openTips, setOpenTips] = useState<boolean>(false);


    const onClickOpenTips = useCallback(
        () => {
            setOpenTips(true)
        },
        [],
    )
    const onClickCloseTips = useCallback(
        () => {
            setOpenTips(false)
            try {
                const localTips = JSON.parse(localStorage.getItem('@tips') ?? `[]`);
                localStorage.setItem('@tips', JSON.stringify([...localTips, tips?.id]))
            } catch (error) {
                console.error(error)
            }
        },
        [tips?.id],
    )

    useEffect(() => {
        if (tips) {
            const localTips: string[] = JSON.parse(localStorage.getItem('@tips') ?? `[]`);
            const storageIdTips = localTips.find(item => item === tips.id);
            if (!storageIdTips) {
                setOpenTips(true)
            } else {
                setOpenTips(false)
            }
        }
    }, [tips])





    return (
        <TipsContext.Provider value={{
            openTips,
            onClickOpenTips,
            onClickCloseTips,
            setTips,
            tips
        }}>
            <>{children}</>
        </TipsContext.Provider>
    );
};


export const useTips = () => {
    const context = useContext(TipsContext);
    return context;
};